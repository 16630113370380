import { provider } from 'web3-core';
import { writable } from 'svelte/store';

export interface ProviderInfo {
    uuid: string;
    name: string;
    icon: string;
}

export interface Provider {
    info: ProviderInfo;
    provider: any;
}

export const walletConnectProvider = {
    info: {
        uuid: 'walletconnect',
        name: 'WalletConnect',
        icon: 'wallets/walletConnect.png',
    },
    provider: null,
};

export const Provider = writable<provider | null>(null);
