<script lang="ts">
    import { onMount } from 'svelte';
    import { ChainId, Wallet, WalletConnected } from '../Data/Wallet';
    // import { Chains, goodRPCs } from "../Data/Chains";
    import chains from '../Data/Chains';
    import { connect } from '../API/Wallet';
    import { createEventDispatcher } from 'svelte';
    import { web } from '../Data/Web3Store';
    import { MenuTexts, Locale, SkipTexts, isSupportedLanguage } from '../Data/Locale';
    import { push, location } from 'svelte-spa-router';
    import Contracts from '../Data/Contracts';
    import { DEXPreload, showDEXModal } from '../Data/DEXPreload';
    import BigNumber from 'bignumber.js';

    let showMobileMenu: boolean = false;
    let walletDisplay: string = '';
    const dispatch = createEventDispatcher();

    let chainIcon = chains[$ChainId]?.logoURI;
    let backupIcon = '/images/chains/unknown.png';

    $: ($WalletConnected || $ChainId || $Wallet?.address) && updateChainIcon();

    let showModal = false;

    const handleConnect = (event: { detail: { provider: any; account: any } }) => {
        const { provider, account } = event.detail;
        console.log('Connected to', provider.info.name, 'with account', account);
    };

    onMount(() => {
    });

    const updateChainIcon = () => {
        console.log('chainIcon updateChainIcon', $ChainId, chains[$ChainId]);
        chainIcon = chains[$ChainId]?.logoURI;
        if(!chainIcon) chainIcon = backupIcon;
        console.log('chainIcon', chainIcon);
    };
    const onShowMenuClick = () => (showMobileMenu = !showMobileMenu);

    const onLocaleClick = (locale: string) => {
        showMobileMenu = false;
        if (!!locale && isSupportedLanguage(locale)) $Locale = locale;
        else $Locale = 'en';
    };

    let oldWallet = $Wallet.address;

    $: ($Wallet || $ChainId) && getWalletDisplay();
    $: $Wallet.address != oldWallet && getWalletDisplay();

    const onMenuItemClick = (id: string) => {
        console.log('current route', $location);
        if ($location == '/integrate') push('/');
        if (id == 'skipscan') {
            const url = $WalletConnected ? 'https://skipscan.win/address/' + $Wallet.address : 'https://skipscan.win';
            window.open(url, '_blank');
            return;
        }
        const element = document.getElementById(id);
        if (element) {
            const y = element.getBoundingClientRect().top + window.scrollY - 50;
            window.scrollTo({ top: y, behavior: 'smooth' });
        }
    };

    const onWalletConnectClick = async () => {
        if ($WalletConnected) return;
        await connect();
    };

    const onIntegrate = () => {
        push('/integrate');
    };

    const onDEX = () => {
        push('/dex');
    };

    //TODO: remove
    const onTestDEXModal = () => {
        DEXPreload.set({ 
                receiving_chain_id: 56, 
                receiving_token_address: '0x55d398326f99059ff775485246999027b3197955',
                receiving_token_symbol: 'USDT',
                trade_size: BigNumber(100),
                wallet_balance: BigNumber(88)
            });
        showDEXModal.set(true);

        console.log('showing dex modal', $showDEXModal, $DEXPreload)
    }

    const getWalletDisplay = () => {
        console.log('getWalletDisplay wallet changed: ', $Wallet.address);
        if ($Wallet?.address == '' || !$Wallet?.address || $Wallet.address == Contracts.ZERO) return;
        $Wallet.address = $web.utils.toChecksumAddress($Wallet.address);
        oldWallet = $Wallet.address;
        walletDisplay = $Wallet.address.slice(0, 6) + '...' + $Wallet.address.slice(38);
        return walletDisplay;
    };

    const toggleTheme = () => {
        const htmlElement = document.body.parentElement;
        const currentTheme = htmlElement?.getAttribute('data-theme') || 
                            (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light');

        const newTheme = currentTheme === 'dark' ? 'light' : 'dark';
        
        htmlElement?.setAttribute('data-theme', newTheme);
    };

</script>

<div class="menu-background z-10 w-screen fixed top-0 bg-opacity-80 flex">
    <div class="flex items-center py-2 px-4 max-w-[95%] grow">
        <div class="flex m-auto">
            <button on:click={(_) => push('/')}>
                <img class="skiplogo" src="/SkipShift.png" alt="SkipShift" />
            </button>
        </div>
        <div class="flex items-center justify-items-center md:hidden">
            <button on:click={onShowMenuClick}>
                <img src="/icons/menu.svg" alt="burger menu icon" />
            </button>
        </div>
        <div class="md:flex w-[67%] items-center justify-center hidden">
            <button class="px-2" on:click={(_) => onMenuItemClick('products')}>{MenuTexts[$Locale].products}</button>
            <button class="px-2" on:click={(_) => onMenuItemClick('partners')}>{MenuTexts[$Locale].partners}</button>
            <button class="px-2" on:click={(_) => onMenuItemClick('methods')}>{MenuTexts[$Locale].venues}</button>
            <button class="px-2" on:click={(_) => onMenuItemClick('blurb')}>{MenuTexts[$Locale].about}</button>
            <button class="px-2" on:click={onIntegrate}>{'Integrate'}</button>
            <!-- <button class="px-2" on:click={onDEX}>{'DEX'}</button> -->
            <button on:click={(_) => onMenuItemClick('skipscan')} class="px-2 text-sky-500">{'SkipScan'}</button>
        </div>
        <!-- <button on:click={() => showModal = true}>Connectation</button>

        {#if showModal}
        <WalletModal on:connect={handleConnect} on:close={() => showModal = false} />
        {/if} -->
        <div class="justify-end items-center flex-grow hidden md:flex md:flex-row-reverse">
            <!-- <div class="flex items-center">
                <button on:click={onWalletConnectClick} class="flex items-center text-white text-sm buy-sell" id="_connect_wallet">
                    {$WalletConnected ? walletDisplay : SkipTexts[$Locale].connect_wallet}
                    <img
                        class={`w-4 ml-3 mr-3 rounded-full ${!$WalletConnected ? 'hidden' : ''}`}
                        src={chainIcon}
                        on:error={() => (chainIcon = backupIcon)}
                        alt="chain logo" />
                </button>

            </div> -->
            <button on:click={(_) => dispatch('showNotifsModal')} class="hidden md:flex items-center">
                <img src="/icons/bell.svg" alt="bell" class="w-8 mr-8" />
            </button>
            <div class="items-center justify-end flex-grow hidden md:flex pr-6">
                <button on:click={(_) => onLocaleClick('en')}>
                    <span class="fi fi-gb px-4"></span>
                </button>
                <button on:click={(_) => onLocaleClick('es')}>
                    <span class="fi fi-es px-4"></span>
                </button>
                <button on:click={(_) => onLocaleClick('cz')}>
                    <span class="fi fi-cz px-4"></span>
                </button>
                <button on:click={(_) => onLocaleClick('pt')}>
                    <span class="fi fi-br px-4"></span>
                </button>
                <button on:click={(_) => onLocaleClick('bn')}>
                    <span class="fi fi-bd px-4"></span>
                </button>
            </div>
        </div>
    </div>
    <!-- <button on:click="{() => dispatch('openWallet')}" class="m-4">
        <i class="fad fa-coins text-2xl"></i>
      </button> -->
    <button on:click="{toggleTheme}" class="">
        <i class="fad fa-eclipse text-2xl"></i>
      </button>
</div>

<div class="mobile-menu fixed inset-0 z-10 bg-[#000000a2]" class:show={showMobileMenu}>
    <button on:click={() => (showMobileMenu = false)} class="text-2xl absolute right-0 top-0 p-8">
        <i class="fad text-white fa-times"></i>
    </button>

    <div class="flex flex-col justify-center w-full h-full items-center py-4">
        <!-- <LanguageSelector screen="mobile" languages="{data.languages}" /> -->
        <div class="flex">
            <button on:click={(_) => onLocaleClick('en')}>
                <span class="fi fi-gb px-6"></span>
            </button>
            <button on:click={(_) => onLocaleClick('es')}>
                <span class="fi fi-es px-6"></span>
            </button>
            <button on:click={(_) => onLocaleClick('cz')}>
                <span class="fi fi-cz px-6"></span>
            </button>
            <button on:click={(_) => onLocaleClick('pt')}>
                <span class="fi fi-br px-6"></span>
            </button>
            <button on:click={(_) => onLocaleClick('bn')}>
                <span class="fi fi-bd px-6"></span>
            </button>
        </div>
        <button
            on:click={() => {
                showMobileMenu = false;
                onMenuItemClick('home');
            }}
            class="mobile-button"
            >{'dApp'}
        </button>
        <button
            on:click={() => {
                showMobileMenu = false;
                onMenuItemClick('products');
            }}
            class="mobile-button"
            >{MenuTexts[$Locale].products}
        </button>

        <button
            on:click={() => {
                showMobileMenu = false;
                onMenuItemClick('methods');
            }}
            class="mobile-button"
            >{MenuTexts[$Locale].venues}
        </button>
        <button
            on:click={() => {
                showMobileMenu = false;
                onMenuItemClick('blurb');
            }}
            class="mobile-button"
            >{MenuTexts[$Locale].about}
        </button>
        <button
            on:click={() => {
                showMobileMenu = false;
                onMenuItemClick('subscribe');
            }}
            class="mobile-button"
            >{MenuTexts[$Locale].subscribe}
        </button>
        <a href={$WalletConnected ? 'https://skipscan.win/address/' + $Wallet.address : 'https://skipscan.win'} target="_blank" class="mobile-button"
            >{'SkipScan'}</a>

        <a href={`https://skipshift.io/#/integrate`} class="bubble-border bg-fuchsia-500 px-4 text-2xl py-2 my-3 hover:bg-fuchsia-700 text-white">
            Integrate
        </a>
        <button
            class="bubble-border bg-sky-500 px-4 text-2xl py-2 my-3 hover:bg-fuchsia-700 text-white"
            on:click={(_) => {
                showMobileMenu = false;
                dispatch('showNotifsModal');
            }}>
            Notifications
        </button>
    </div>
</div>


<style lang="scss">

    .menu-background{
        background-color: var(--top-bar-bg);
        color: var(--top-bar-text);
    }
    .mobile-button {
        @apply md:ml-8 text-3xl py-2 my-2 text-white hover:text-sky-500;
    }

    .buy-sell {
        border-radius: 8px;
        padding: 0.5rem 1rem;
        background: var(--primary-blue, #1a98fe);
    }

    .set-black {
        @apply bg-black/90;
    }

    .skiplogo {
        width: 10em;
    }

    .mobile-menu {
        transition: transform 500ms ease-in-out;
        transform: translateX(100%);
        backdrop-filter: blur(8px);
    }

    .mobile-menu.show {
        transform: translateX(0);
    }
</style>
