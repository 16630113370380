<script lang="ts">
    import { web } from '../../Data/Web3Store';
    import { ChainId, Wallet, WalletConnected } from '../../Data/Wallet';
    import { onMount } from 'svelte';
    import { createEventDispatcher } from 'svelte';
    import Contracts from '../../Data/Contracts';
    import Web3 from 'web3';
    import CountdownButton from './CountdownButton.svelte';
    import { connect, disconnect } from '../../API/Wallet';
    import { isWallet, initXmtp } from '../../API/Chat';
    import { addActionLogLine, ShowActionLogs } from '../../Data/ActionLogs';
    import { SkipShiftContracts } from '../../Data/P2P';
    import { USER_SETTINGS } from '../../Data/TransactionSettings';
    import { Provider, walletConnectProvider } from '../../Data/Provider';

    let closeButtonCount: number = 5;
    let providers: Provider[] = [];
    let selectedProvider: Provider | null = null;
    let userAccount: string = '';
    const dispatch = createEventDispatcher();

    const subscribe = (callback: () => void) => {
        function onAnnouncement(event: Event) {
            const customEvent = event as CustomEvent<Provider>;
            if (providers.some((p) => p.info.uuid === customEvent.detail.info.uuid)) return;
            providers = [...providers, customEvent.detail];
            callback();
        }

        window.addEventListener('eip6963:announceProvider', onAnnouncement as EventListener);
        window.dispatchEvent(new Event('eip6963:requestProvider'));

        return () => window.removeEventListener('eip6963:announceProvider', onAnnouncement as EventListener);
    };

    onMount(() => {
        console.log('WalletProviders mounted');
        const unsubscribe = subscribe(() => {
            providers = [...providers];
        });

        providers = [...providers, walletConnectProvider];
        console.log('WalletProviders providers: ', providers);
        return unsubscribe;
    });

    const onConnectWallet = async () => {
        await connect();

        if (!SkipShiftContracts[$ChainId] && $ChainId != 0) {
            $ShowActionLogs = true;
            addActionLogLine('Unsupported chain. Please switch to ETH, BSC, or AVAX.');
            $ShowActionLogs = false;
        }

        if (!(await isWallet())) {
            const init = await initXmtp();
            if (!init) return;
        }
    };

    const handleConnect = async (provider: Provider) => {
        // closeButtonCount = 0;
        selectedProvider = null;
        userAccount = null;

        try {
            console.log('handleConnect provider: ', provider);
            selectedProvider = provider;
            if (provider.info.name == 'WalletConnect') {
                await connect(null, true);
            } else {
                // const accounts: string[] = await provider.provider.request({ method: 'eth_requestAccounts' });
                // console.log('Connected to', provider.info.name, 'with account', accounts[0]);
                // if (accounts && accounts[0]) {
                    // console.log('Connected to', provider.info.name, 'with account', accounts[0]);
                    // userAccount = accounts[0];
                    await connect(provider.provider);
                // }
            }
            userAccount = $Wallet.address;
        } catch (error) {
            disconnect();
            selectedProvider = null;
            userAccount = null;
            console.error(error);
        }
    };

    const getWalletDisplay = (wallet: string) => {
        let walletDisplay: string = '';
        let web3 = new Web3();
        console.log('getWalletDisplay wallet changed: ', wallet);
        if (wallet == '' || !wallet || wallet == Contracts.ZERO) return;
        wallet = web3.utils.toChecksumAddress(wallet);
        walletDisplay = wallet.slice(0, 8) + '...' + wallet.slice(36);
        return walletDisplay;
    };

    const closeModal = () => {
        if($Wallet.address) dispatch('verify');
        dispatch('close');
    };
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<div class="modal z-[4]" on:click|preventDefault|stopPropagation={closeModal}>
    <div class="z-[5] modal-content py-3 px-4 md:px-6 flex flex-col" on:click|preventDefault|stopPropagation>
        <div class="flex items-center">
            <h1 class="w-full text-sm">Connect with:</h1>
            <button class="close self-center" on:click={closeModal}><i class="fad fa-times-circle text-amber-300 text-xl"></i></button>
        </div>
        <div class="flex flex-wrap justify-center">
            {#if providers.length > 0}
                {#each providers as provider}
                    <button on:click|stopPropagation={() => handleConnect(provider)}>
                        <img src={provider.info.icon} alt={provider.info.name} />
                        <div class="text-sm">{provider.info.name}</div>
                    </button>
                {/each}
            {:else}
                <div class="text-sm">There are no announced providers.</div>
            {/if}
        </div>
        <hr class="py-2" />
        <h2 class="text-sm text-center">
            {@html userAccount ? 'Selected Wallet <i class="fas fa-check-circle" style="color: #1a9e23;"></i>' : 'Select a Wallet Provider'}
            <!-- <i class="fad fa-check-circle"></i> -->
        </h2>
        {#if selectedProvider}
            <div class="flex flex-wrap justify-evenly pt-2">
                <div>
                    <img src={selectedProvider.info.icon} alt={selectedProvider.info.name} class="max-w-6 m-auto" />
                </div>
                <!-- <div class="text-sm">{selectedWallet.info.name}</div> -->
                {#if userAccount}
                    <div class="text-xs self-center">{userAccount}</div>
                {/if}
            </div>

            {#if $WalletConnected}
                <div class="w-40 m-auto h-full items-end flex">
                    <CountdownButton on:close={closeModal} count={closeButtonCount} />
                </div>
            {/if}
        {/if}
    </div>
</div>

<style lang="scss">
    .modal {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
    }

    .modal-content {
        background: white;
        // padding: 1rem 0.75rem;
        border-radius: 8px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        color: white;

        // @apply md:min-w-[27.458rem] md:max-w-[27.458rem];
        // @apply max-h-[39.688rem] min-h-[39.688rem];
        width: 25rem;
        height: 20rem;
        max-width: 97vw;
        max-height: 95vh;
        border-radius: 16px;
        // z-index: 3;
        background: var(--home-bg);
        box-shadow:
            0px 10px 2px 0px rgba(0, 0, 0, 0.1),
            0px 8px 4px 0px rgba(0, 0, 0, 0.04),
            0px 24px 16px 0px rgba(0, 0, 0, 0.04),
            0px 32px 24px 0px rgba(0, 0, 0, 0.01),
            0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        overflow: hidden;
        border: 1px solid #062057;
    }

    .modal-content h1 {
        font-size: 1rem;
        @apply italic text-left;
    }

    .close {
        align-self: flex-end;
        cursor: pointer;
    }

    button {
        margin: 5px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0.2rem;
    }

    button img {
        width: 2rem;
        height: 2rem;
        border-radius: 25%;
    }
</style>
